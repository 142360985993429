
/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';
import { Form } from 'element-ui';
import { withdrawUser } from '@/api/user';
import { UserModule } from '@/store/modules/user';

@Component({
})

export default class extends Vue {
  private form = {
    reason: '',
    confirmTxt: '',
  }

  private confirmTextValidator: any = (rules: any, value: any, callback: Function) => {
    if (value !== '웨일리잇 회원탈퇴') {
      callback(new Error('정확한 문구를 입력해주세요.'));
    }
    callback();
  };

  private rules: any = {
    reason: [
      { required: 'true', message: '탈퇴사유를 입력해주세요.', trigger: ['blur', 'change'] },
    ],
    confirmTxt: [
      { validator: this.confirmTextValidator, trigger: ['blur', 'change'] },
    ],
  };

  private handleWithdraw() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        withdrawUser(this.form).then(() => {
          this.$alert('회원탈퇴가 완료되었습니다.');
          UserModule.LogOut();
          this.$router.push({ name: 'Home' });
        });
      }
    });
  }
}
